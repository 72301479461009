import { createMuiTheme } from '@material-ui/core/styles';

export const USER = "userName";
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const SERVER_STATUS = "serverStatus";
export const SERVER_STR = "serverStr";

// Material ui theming
// https://material-ui.com/customization/theming/
export const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: "#0086a8",
            dark: '#002884',
            contrastText: '#fff ',
        },
        secondary: {
            light: "#4f5b62",
            main: "#263238",
            dark: "#000a12",
        },
    },
    typography: {
        h5: {
            color: "#FFFFFF"
        }
    }
});
