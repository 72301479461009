import React, { useState } from "react";
import { Typography} from "@material-ui/core";
import { useEffect } from "react";
import { serverData } from "../../data/api";
import { useAuthState } from "../../state/AuthState";
import "./applications-page.scss";
import { Button,TableRow,TableCell} from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

const DevicesPage = () => {
  const [applicationData, setapplicationData] = useState(null);
  const { state, dispatchAuth } = useAuthState();
  const navigate = useNavigate();

    useEffect(() => {
      serverData(`/bee/appinstallapi/v1/deviceEvents/`,{},'GET',true,dispatchAuth,(resp,status)=>{
         if(status===200){
            setapplicationData(resp);
          } else{
            console.log("getApps default FAIL");
            setapplicationData(null);
          }
        })
    },[]);

    const onClickRefresh = () => {
      window.location.reload();
    }
    const onClickAddDeviceSpecificApps = () => {
      navigate('/deviceapplications');
    }

    return (
      <div className="application-page">
      <Typography  className="title" style={{ margin: 24 }} variant="h5">
      <b>Devices</b>
      <br></br> <br></br>

      </Typography>
       <Table size="small" style={{ minWidth: 300 }}>
        <TableHead>

          <TableRow>
          <TableCell><b>Device</b></TableCell>
          <TableCell><b>Latest status</b></TableCell>
          <TableCell><b>Time</b></TableCell>
          <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => onClickRefresh()}
                    style={{ marginTop: 8  }}
                >
                    Refresh events
              </Button>
              <br></br>
              <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => onClickAddDeviceSpecificApps()}
              style={{ marginTop: 8, margin: 8 }}
          >
              Add device specific applications
          </Button>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            ( applicationData === null ) ? <div><b>No events</b></div> :
            applicationData.map((d) =>
            <TableRow key={d._id}>
            <TableCell>
            <NavLink  style={{color: 'blue', fontSize:18}}
                to={{
                  pathname:'/deviceapplications',
                  search: '?srn='+d._id,
                  color: '#F00',
                  }}>
                  {d._id}
                </NavLink>

            </TableCell>
            <TableCell>{d.event_string}</TableCell>
            <TableCell>{d.event_time}</TableCell>
            </TableRow>
          )
          }
          </TableBody>
      </Table> <br></br>
      </div>
    );
}

export default DevicesPage;
