import React, { useState } from "react";
import { Typography} from "@material-ui/core";
import { serverData } from "../../data/api";
import { useAuthState } from "../../state/AuthState";
import "./applications-page.scss";
import { TextField, Button,TableRow,TableCell,Snackbar} from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import MuiAlert from '@material-ui/lab/Alert';
import { useEffect } from "react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DeviceApplicationsPage = () => {
    const [loading, setLoading] = useState(false);
    const [applicationData, setapplicationData] = useState(null);
    const [errorSnackOpen, showErrorSnack] = useState(false);
    const [SnackError, setSnackError ] = useState(null);
    const [serialNbr, setSerialNbr] = useState("");
    const {state, dispatchAuth } = useAuthState();
    const [appUpdateApkName, setUpdateAppApkName] = useState("");
    const [appUpdateUrl, setAppUpdateUrl] = useState("");
    const [appUpdateVersion, setAppUpdateVersion] = useState("");
    const [appAddAppName, setAddAppName] = useState("");
    const [appAddApkName, setAddAppApkName] = useState("");
    const [appAddUrl, setAppAddUrl] = useState("");
    const [appAddVersion, setAppAddVersion] = useState("");
    const [igonoreQueryParam, setIgonoreQueryParam] = useState(false);
    const [deviceFoundProp, setdeviceFoundProp] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);

    var deviceFound=false;
    useEffect(() => {
      console.log("useEffect:"+serialNbr);
      if (!igonoreQueryParam){
        setSerialNbr (queryParams.get('srn'));
        console.log(queryParams.get('srn'));
    }
      onClickSearchDevice();
    },[serialNbr]);

    const handleEnter = (e) => {
      if (e.key === "Enter") {
        onClickSearchDevice();
      }
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    showErrorSnack(false);
};

  const onChangeSrialNbr = (e) => {
    setIgonoreQueryParam (true);
    setSerialNbr(e.target.value);
    console.log("onChangeSrialNbr:"+serialNbr);
}

  const onClickSearchDevice = () => {
    setLoading(true);

    if (serialNbr===null || serialNbr.length === 0){
      deviceFound=false;
      setdeviceFoundProp(false);
      return;
    }
    console.log("onClickSearchDevice:"+serialNbr);
    serverData(`/bee/appinstallapi/v1/getApps/`+ serialNbr +"?filter=no_default",{},'GET',true,dispatchAuth,(resp,status)=>{
    console.log("getApps done start");
    setLoading(false);
    if(status===200 && resp.apps.length > 0){
        console.log(resp);
        deviceFound=true;
        setdeviceFoundProp(true);
      setapplicationData(resp.apps);
    }else{
      //setSnackError("Device not found")
      //showErrorSnack(true);
      deviceFound=false;
      setdeviceFoundProp(false);
      setapplicationData(null);
    }
  })
}

  const onClickRemoveDevice = () => {
    console.log("onClickRemoveDevice:"+serialNbr);
    const body = {serialnumber:serialNbr, sbc:"NA"};
    serverData(`/bee/appinstallapi/v1/deleteDevice/`,body,'POST',true, dispatchAuth ,(resp,status)=>{
      if(status===200){
      }else{
        setSnackError("Remove failed " + status );
        showErrorSnack(true)
      }
    })
    window.location.reload();
}

const onClickRemoveApp = (appName) => {
    const body = {serialnumber:serialNbr, app_name:appName};
    serverData(`/bee/appinstallapi/v1/removeApplicationDataOne/`,body,'POST',true, dispatchAuth ,(resp,status)=>{
      if(status===200){
      }else{
        setSnackError("Remove failed " + status );
        showErrorSnack(true)
      }
      setapplicationData(null);
      onClickSearchDevice();
    })
  }

const onClickAddApplication = () => {
    const body = {serialnumber:serialNbr, app_name:appAddAppName, app_version:appAddVersion,app_url:appAddUrl,app_apk:appAddApkName};
    serverData(`/bee/appinstallapi/v1/addAppDataOne/`,body,'POST',true, dispatchAuth ,(resp,status)=>{
    if(status===200){
      }else{
        setSnackError("Update failed " + status );
        showErrorSnack(true)
      }
      setapplicationData(null);
      onClickSearchDevice();
    })

    //window.location.reload();
  }
  const onClickUpdate = (AppName,AppVersion,AppUrl,AppApk) => {
    console.log("onClickUpdate " + serialNbr +  " " + AppName+  " " + AppVersion +  " " + AppUrl +  " " + AppApk );
    const isVersionUpdated = appUpdateVersion !== "";
    const isUrlUpdated = appUpdateUrl !== "";
    const isApkNameUpdated = appUpdateApkName !== "";

    if (isVersionUpdated) {
      AppVersion = appUpdateVersion;
    }
    if (isUrlUpdated) {
      AppUrl = appUpdateUrl;
    }
    if (isApkNameUpdated) {
      AppApk = appUpdateApkName;
    }

    if (!isVersionUpdated && !isUrlUpdated && !isApkNameUpdated) {
      console.log("No updates");
      return;
    }

    const body = {serialnumber:serialNbr, app_name: AppName, app_version:AppVersion,app_url:AppUrl,app_apk:AppApk};
    serverData(`/bee/appinstallapi/v1/updateAppDataOne/`,body,'POST',true, dispatchAuth ,(resp,status)=>{
    if(status===200){
      console.log("updateAppDataOne OK");
    }else{
      setSnackError("Update failed " + status );
      showErrorSnack(true)
    }
    onClickSearchDevice();
    })
  }

  const onChangeUrl = (e) => {
    setAppUpdateUrl (e.target.value);
  }

  const onChangeApk = (e) => {
    setUpdateAppApkName (e.target.value);
  }

  const onChangeVersion = (e) => {
    setAppUpdateVersion (e.target.value);
  }

  const onAddUrl = (e) => {
    setAppAddUrl (e.target.value);
  }

  const onAddApk = (e) => {
    setAddAppApkName (e.target.value);
  }

  const onAddVersion = (e) => {
    setAppAddVersion (e.target.value);
  }

  const onAddName = (e) => {
    setAddAppName (e.target.value);
  }
  console.log("deviceFoundProp:"+deviceFoundProp);

  return (
      <div className="application-page">

      <Typography  className="title" style={{ margin: 24 }} variant="h5">
      <b>Device specific applications</b>
      <br></br>
      <Table style={{ minWidth: 100 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    margin="normal"
                    id="srn"
                    label="Serial number"
                    value={igonoreQueryParam ? null:serialNbr}
                    onChange={onChangeSrialNbr}
                    onKeyPress={handleEnter}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        </Table>
       </Typography>
       <Table style={{ minWidth: 300 }}>
        <TableHead>
          <TableRow>
            <TableCell><b>Application</b></TableCell>
            <TableCell><b>Version</b></TableCell>
            <TableCell><b>Url</b></TableCell>
            <TableCell><b>Apk name</b></TableCell>
            <TableCell>
              {deviceFoundProp ?
              (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                hidden={!deviceFoundProp}

                disabled={!deviceFoundProp}
                onClick={() => onClickRemoveDevice()}
                style={{ margin: 8 }}>
                Remove applications
              </Button>
              ):null}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            ( applicationData === null ) ? <div><b>No applications</b></div> :
            applicationData.map((d,index) =>
            <TableRow key={d.app_name}>
            <TableCell>{d.app_name}</TableCell>
            <TableCell><TextField defaultValue={d.app_version} margin="normal" variant="outlined" type="text" onChange={onChangeVersion} />  </TableCell>
            <TableCell><TextField defaultValue={d.app_url} margin="normal" variant="outlined" type="text" onChange={onChangeUrl} /> </TableCell>
            <TableCell><TextField defaultValue={d.app_apk} margin="normal" variant="outlined" type="text" onChange={onChangeApk} /></TableCell>
            <TableCell>
          <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => onClickUpdate(d.app_name,d.app_version,d.app_url,d.app_apk)}
              style={{ marginTop: 8,margin: 8 }}
          >
              Update
          </Button>

          <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => onClickRemoveApp(d.app_name)}
              style={{ marginTop: 8, margin: 8 }}
          >
              Remove
          </Button>
          </TableCell>
            </TableRow>
          )
          }
          </TableBody>
      </Table> <br></br>
      <TableRow >
      <b>Add new application</b>
      </TableRow>
      <TableRow >
            <TableCell><TextField label="Name" margin="normal" variant="outlined" type="text" onChange={onAddName} />  </TableCell>
            <TableCell><TextField label="Version" margin="normal" variant="outlined" type="text" onChange={onAddVersion} /> </TableCell>
            <TableCell><TextField label="Url" margin="normal" variant="outlined" type="text" onChange={onAddUrl} /> </TableCell>
            <TableCell><TextField label="Apk name" margin="normal" variant="outlined" type="text" onChange={onAddApk} /> </TableCell>
            <TableCell>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => onClickAddApplication()}
              style={{ marginTop: 8 }}>
              Add
            </Button>
          </TableCell>
      </TableRow>
      <Snackbar open={errorSnackOpen} autoHideDuration={3000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error">
                    Error: {SnackError}
                </Alert>
            </Snackbar>
  </div>
    );
}

export default DeviceApplicationsPage;
