import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Paper, TextField, Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { serverData } from "../../data/api";
import "./login-page.scss"
import { useAuthState } from "../../state/AuthState";
import { SERVER_STATUS,SERVER_STR } from "../../constants";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoginPage = () => {
    const [userName, setUserName] = useState("");
    const [passWord, setPassword] = useState("");
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [errorSnackOpen, showErrorSnack] = useState(false);
    const [serverStatus, setServerStatus] = useState(true);
    const { dispatchAuth } = useAuthState();
    const navigate = useNavigate();

    useEffect(() => {
      serverData(`/bee/usermngapi/v1/config`,{},'GET',false,dispatchAuth,(resp,status)=>{

        if(status===200){

          if (resp.server_available==='true'){
            setServerStatus(true);
            localStorage.setItem(SERVER_STATUS, true);
          } else if (resp.server_available==='false') {
            setServerStatus(false);
            localStorage.setItem(SERVER_STATUS, false);
          }
          console.log(resp.info_str);
          if (resp.info_str){
            localStorage.setItem(SERVER_STR, resp.info_str);
          }else{
            localStorage.setItem(SERVER_STR, "");
          }
        }
        });

    });

    const onClick = () => {
        const isPasswordEmpty = passWord === "";
        const isUsernameEmpty = userName === "";

        if (isUsernameEmpty) {
            setUsernameError(true);
        }
        if (isPasswordEmpty) {
            setPasswordError(true);
        }
        if (isUsernameEmpty || isPasswordEmpty) {
            return;
        }

        const body = { username:userName, password:passWord };
        serverData(`/bee/usermngapi/v1/login`,body,'POST',false,dispatchAuth,(resp,status)=>{
          console.log("login done start");

          if(status===200){
            if (resp.access_token && resp.refresh_token) {
              console.log("tokens found");
              dispatchAuth({ type: "sign_in", payload:
                { username:userName, access_token: resp.access_token,
                  refresh_token: resp.refresh_token
                }
              });
              navigate("/defaultapplications");
            } else {
              showErrorSnack(true);
            }
          }else{showErrorSnack(true);}
        })
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        showErrorSnack(false);
    };

    const changeUsername = (e) => {
        setUserName(e.target.value);
        setUsernameError(false);
    }

    const changePassword = (e) => {
        setPassword(e.target.value);
        setPasswordError(false);
    }

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            onClick();
        }
    }

    return (
        <Container maxWidth="sm" className="login-page">
            <h1>Log in</h1>

            <Paper style={{ padding: 8 }}>
                <div className="form">
                    <TextField
                        disabled={!serverStatus}
                        variant="outlined"
                        margin="normal"
                        error={usernameError}
                        helperText={usernameError ? "Empty username" : ""}
                        onChange={changeUsername}
                        value={userName}
                        required
                        fullWidth
                        id="email"
                        label="Username"
                        name="UserName"
                        autoComplete="Username"
                        autoFocus
                        onKeyPress={handleEnter}
                    />
                    <TextField
                        variant="outlined"
                        disabled={!serverStatus}
                        error={passwordError}
                        helperText={passwordError ? "Empty password" : ""}
                        onChange={changePassword}
                        value={passWord}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onKeyPress={handleEnter}
                    />
                    <Button
                        disabled={!serverStatus}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => onClick()}
                        style={{ marginTop: 8 }}
                    >
                        Sign In
                    </Button>
                </div>
            </Paper>
            <Snackbar open={errorSnackOpen} autoHideDuration={3000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error">
                    Login failed, check username and password and try again!
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default LoginPage;