import { REFRESH_TOKEN, ACCESS_TOKEN, USER } from "../constants";

export const reduce = (state, action) => {
    switch (action.type) {
        case "sign_out": {
            localStorage.removeItem(REFRESH_TOKEN);
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(USER);
            return {
              username: null,
              access_token: null,
              refresh_token: null,
            }
        }
        case "sign_in": {
            var { username, access_token, refresh_token } = action.payload;
            localStorage.setItem(ACCESS_TOKEN, access_token);
            localStorage.setItem(REFRESH_TOKEN, refresh_token);
            localStorage.setItem(USER, username);
            return {
                username,
                access_token, 
                refresh_token
            }
        }          
        default:
            return state;
    }
}