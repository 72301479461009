import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Routes, Route, Router, Navigate, useNavigate,Outlet  } from 'react-router-dom';
import DeviceApplicationsPage from './components/application/device-applications-page';
import DefaultApplicationsPage from './components/application/default-applications-page';
import DevicesPage from  './components/application/devices-page';
import NavBar from './components/common/navbar';
import LoginPage from './components/login/login-page';
import { useAuthState } from "./state/AuthState";
import { useEffect } from "react";
import { REFRESH_TOKEN, ACCESS_TOKEN, USER } from "./constants";



const PrivateRoute = ({ children }) => {

    const { state } = useAuthState();
    const { dispatchAuth } = useAuthState();
    const navigate = useNavigate();
    const refresh_token = localStorage.getItem(REFRESH_TOKEN);
    const access_token = localStorage.getItem(ACCESS_TOKEN);
    const username = localStorage.getItem(USER);
    const signedIn = (state && state.access_token) || access_token;

    useEffect(() => {

        if (!access_token) {
            navigate("/login");
        } else {
            dispatchAuth({ type: "sign_in", payload: { username, access_token, refresh_token } });
        }
    }, [username, access_token, refresh_token]);

    return signedIn ? children : <Navigate to="/login" />
  };

const AuthenticatedApp = () => {
    const navigate = useNavigate();
    const { dispatchAuth } = useAuthState();

    const onSignOut = () => {
        dispatchAuth({ type: "sign_out" });
        navigate ("/login");
    }

    const NotFound = () => (
        <div style={{ marginTop: 80 }}>
            <Typography variant="h2">Not found!</Typography>
            <Button color="primary" variant="contained" onClick={() => navigate("/login")} style={{ marginTop: 16 }}>
                Login page
            </Button>
        </div>
    );

    return (
        <div>
                <NavBar onSignOut={onSignOut} />
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path='/login'  element={<LoginPage/>}></Route>
                    <Route path="/deviceapplications" element={
                    <PrivateRoute>
                           <DeviceApplicationsPage />
                    </PrivateRoute>
                    } />
                    <Route path="/devices" element={
                    <PrivateRoute>
                           <DevicesPage />
                    </PrivateRoute>
                    } />
                    <Route path="/defaultapplications" element={
                    <PrivateRoute>
                           <DefaultApplicationsPage />
                    </PrivateRoute>
                    } />
                    <Route path = "*" element={<NotFound/>} />
                </Routes>
        </div>
    );
}

export default AuthenticatedApp;