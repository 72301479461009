import React from 'react';
import './ServerNotification.scss';

export const ServerNotification = (props) => {
   if (props.message === null) {
     return (<div></div>)
   }
 
   if (props.message.length === 0) {
     return (<div></div>)
   }

   return (
     // eslint-disable-next-line react/react-in-jsx-scope
     <div className={props.type}>
       {props.message}
     </div>
   )
}