import React, { createContext, useReducer, useContext } from "react";
import { reduce } from "./AuthReducer";

export const initialState = {
    username: null,
    access_token: null,
    refresh_token: null
}

const AuthContext = createContext([]);

export const AuthStateProvider = ({ children, initState }) => {
    const [state, dispatch]  = useReducer(reduce, initState || initialState);

    return <AuthContext.Provider value={[state, dispatch]}>{children}</AuthContext.Provider>;
}

export const useAuthState = () => {
    const [state, dispatchAuth] = useContext(AuthContext);

    return { state, dispatchAuth }
}