import React, { useEffect, useState } from "react";
import { useAuthState } from "../../state/AuthState";
import ListItemIcon from '@mui/material/ListItemIcon';
import GridViewIcon from '@mui/icons-material/GridView';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Typography } from '@material-ui/core';
import "./navbar.scss"
import { Menu, MenuItem } from "@material-ui/core";
import logo from "../common/AavaLogo.png"
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { SERVER_STR } from "../../constants";
import { ServerNotification } from "../notifications/ServerNotification";

const NavBar = ({ onSignOut }) => {
    const navigate = useNavigate();
    const { state } = useAuthState();
    const signedIn = state && state.access_token;
    const [anchorEl, setAnchorEl] = useState(null);
    const [serverStr, setServerStr] = useState("");

    useEffect(() => {
      setServerStr(localStorage.getItem(SERVER_STR))
    }, [])


    const menuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onDefaultAppsClick = () => {
      setAnchorEl(null);
      navigate('/defaultapplications');
    };

    const onDeviceEventsClick = () => {
      setAnchorEl(null);
      navigate('/devices');
    };

    const onLogoutClick = () => {
        setAnchorEl(null);
        onSignOut();
    };

    return (
        <div>
        <header className="navbar">

            {signedIn && <div className="dashboard" onClick={menuClick}>
                <GridViewIcon style={{ fill: "#FFFFFF"}} />
                <Typography className="dashboard-icon"  variant="h5">Dashboard</Typography>
            </div>}

            <div className="app-name">
              <h2 >Aava Bee</h2>
            </div>
            <div className="logo-div">
              <img src={logo} alt="Aava Logo" className="logo"/>
            </div>
            <Menu
                id="dashboard-menu"
                className="dasboard-menu"
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
            >
                <MenuItem className="menu-item" onClick={onDefaultAppsClick}>
                  <ListItemIcon>
                    <AddCircleIcon fontSize="small" />
                  </ListItemIcon>
                  Default applications
                </MenuItem>
                <MenuItem className="menu-item" onClick={onDeviceEventsClick}>
                  <ListItemIcon>
                    <AddCircleIcon fontSize="small" />
                  </ListItemIcon>
                  Devices
                </MenuItem>

                <MenuItem className="menu-item" onClick={onLogoutClick}>
                  <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                  </ListItemIcon>
                  Log out ({state.username})
                  </MenuItem>
            </Menu>

        </header>
        <div className="servernotify">
              <ServerNotification type='info' message={serverStr}/>
            </div>
        </div>
    );

}

export default NavBar;
